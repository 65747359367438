import styled from "styled-components";
import colors from "../../../constants/colors";

export const ChatHeader = styled.div`
  height: 50px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	padding-right: 10px;
  position: relative;
  padding-bottom: 15px;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 47%);
    width: 95%;
    border-bottom: 1px solid ${colors.PRIMARY_GREEN};
  }
  
`;

export const ChatHeaderProfile = styled.img`
	height: 50px;
	width: 50px;
	border-radius: 50%;
`;

export const ChatBody = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
`;

export const ChatContainerMessages = styled.button`
	background-color: transparent;
	border: none;
  cursor: pointer;
	height: 50px;
	width: 100%;
	padding: 10px;
	padding: 0 10px 0 20px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
  transition: 0.5s;

  &:active {
    transform: scale(0.95);
  }
`;

export const ProfilePicture = styled.img`
	height: 50px;
	width: 50px;
	border-radius: 50%;
`;

export const ChatContainerText = styled.span`
	max-width: 300px;
	max-height: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: ${colors.WHITE};
`;