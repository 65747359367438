import { useContext, useEffect, useState } from "react"
import axiosInstance from "../../services/axiosInstance";
import { TeamType } from "../../types/Team";
import Template from "../../components/Template";
import { MenuTitle } from "../Home/styles";
import Team from "./components/Team";
import styled from "styled-components";
import Modal from "../../components/UI/Modal/Modal";
import { TeamModalContent, TitleContainer } from "./styles";
import Button from "../../components/UI/Buttons/Button";
import { Form } from "../../components/Menu/styles";
import Input from "../../components/UI/Inputs/Input/Input";
import Feedback from "../../components/UI/Feedback/Feedback";
import { setFeedbackMassage } from "../../functions/feedbackFunctions";
import colors from "../../components/UI/constants/colors";
import { CloseModal, OpenModal } from "../../functions/modalFunctions";
import { InviteType } from "../../types/Invite";
import OutlinedButton from "../../components/UI/Buttons/OutlinedButton";
import UserContext from "../../contexts/User";

const TeamsContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    cursor: pointer;
    flex-wrap: wrap;
`

const Teams = () => {
    const [teams, setTeams] = useState<TeamType[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<TeamType | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [invite, setInvite] = useState<InviteType | undefined>(undefined);
    const userData = useContext(UserContext);

    useEffect(() => {
        loadTeams();
        loadMyInvites();
    }, []);

    const loadMyInvites = () => {
        axiosInstance.get("api/teams/invites").then((res) => {
            const { data } = res;
            if(data.status || data.length < 1) {
                return setInvite(undefined);
            }

            setInvite(data[0]);
            OpenModal("teamInviteModal");
        });
    }

    const loadTeams = () => {
        axiosInstance.get("api/teams/my-teams").then((res) => {
            const { data } = res;

            if(data.status != undefined) {
                setTeams([]);
                return;
            }

            setTeams(data);
        })
    }

    const loadSelectTeamData = async (id:number) => {
        await axiosInstance.get(`/api/teams/${id}`)
        .then((response) => {
            if(response.data.status) {
                return;
            }

            setSelectedTeam(response.data);
        });
    }

    const kick = async(user_id:number) => {
        await axiosInstance.post(`/api/teams/${selectedTeam?.id}/kick`, {
            team_id: selectedTeam?.id,
            user_id
        })
        .then((response) => {
            loadSelectTeamData(selectedTeam?.id || 0);
        })
        .catch((error) => {
            alert(error.response.data.message);
        });
    }

    const createTeam = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		const fd = new FormData(event.currentTarget);
		axiosInstance.post("/api/teams", fd).then(res => {
			CloseModal("createTeamModal");
            loadTeams();
		}).catch((error) => {
			if(error.response.data.message) {
				setFeedbackMassage({ message: error.response.data.message, success: false, id: "feedbackCreateTeam" });
			}
			else {
				setFeedbackMassage({ message: error.response.status, success: false, id: "feedbackCreateTeam" });
			}
		}).finally(() => {
			setLoading(false);
		});
	};

    const inviteMember = () => {
        const inputContainer = document.querySelector("#inviteInputContainer");

        if(!inputContainer?.classList.contains("open")){
            inputContainer?.classList.add("open");
            return;
        }

        const nickname = (document.querySelector("#inviteNicknameInput") as HTMLInputElement)?.value;

        if(nickname == "") {
            alert("Preencha o nickname");
            return;
        }

        axiosInstance.post(`/api/teams/${selectedTeam?.id}/invite/${nickname}`)
        .then((response) => {
            alert("Convite enviado!");
        })
        .catch((error) => {
            const error_message = error.response.data.error;
            if(!error_message) {
                alert("Erro ao convidar jogador.");
            }

            alert(error_message);
        });
    }

    const answerInvite = (team_id:number | undefined, answer:boolean) => {
        if(team_id === undefined) return;

        axiosInstance.post(`/api/teams/${team_id}/join`, {
            answer
        }).then((response) => {
            if(answer) {
                alert("Convite aceito!");
                loadTeams();
            } else {
                alert("Convite rejeitado!");
            }

            CloseModal("teamInviteModal");
        })
        .catch((error) => {
            alert(error.response.data.error);
        });
    }

    const leaveTeam = () => {
        axiosInstance.post(`/api/teams/${selectedTeam?.id}/leave`)
        .then((response) => {
            CloseModal("selected_team_modal");
            loadTeams();
        })
        .catch((error) => {
            const err_message = error.response.data.error;

            if(!err_message)
                alert("Falha ao sair do time.");

            alert(err_message);
        });
    }

    const deleteTeam = () => {
        axiosInstance.post(`/api/teams/${selectedTeam?.id}/delete`)
        .then((response) => {
            CloseModal("selected_team_modal");
            loadTeams();
        })
        .catch((error) => {
            const err_message = error.response.data.error;

            if(!err_message)
                alert("Falha ao apagar o time.");

            alert(err_message);
        });
    }
    
    return(
    <Template text="Meus Times">
        <TitleContainer>
            <MenuTitle>Meus Times</MenuTitle>
            <Button onClick={() => OpenModal("createTeamModal")}>Criar Time</Button>
        </TitleContainer>
        <TeamsContainer>
            { teams?.map((team) => <Team selectTeamFunction={() => loadSelectTeamData(team.id)} key={team.id} team={team} /> ) }
        </TeamsContainer>

        <Modal id="selected_team_modal">
            <TeamModalContent>
                <img src={selectedTeam?.icon} alt={`Ícone do time ${selectedTeam?.name}`} className="picture" />
                <span className="title">{selectedTeam?.name}</span>
                <span className="members-count">{selectedTeam?.members_count}/30 membros</span>
                <span className="title member-title">Membros</span>
                <div className="member-list">
                    {selectedTeam?.members?.map((member) => (
                        <div key={member.id} className="member">
                            <div className="user-data">
                                <img src={member.profile_picture} alt="members picture" className="profile_picture" />
                                <span className="nickname">{member.nickname}</span>
                                {
                                    selectedTeam?.user_id == member.id?
                                    <span className="badge">Dono</span>
                                    :<></>
                                }
                            </div>
                            <div className="actions">
                                {
                                    selectedTeam?.user_id != member.id?
                                    <Button onClick={() => kick(member.id)}>Expulsar</Button>
                                    :<></>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className="invite-container">
                    <div className="input-container" id="inviteInputContainer">
                        <Input type="text" name="nickname" id="inviteNicknameInput">Nickname</Input>
                    </div>
                    { userData?.user?.user ?
                    <Button onClick={inviteMember}>CONVIDAR</Button> : <></> }

                    { userData?.user?.user && (userData?.user?.user.id != selectedTeam?.user_id) ?
                    <OutlinedButton onClick={leaveTeam}>SAIR</OutlinedButton> : 
                    <OutlinedButton onClick={deleteTeam}>APAGAR</OutlinedButton>
                    }
                </div>
            </TeamModalContent>
        </Modal>
        <Modal id="createTeamModal">
            <span style={{fontSize: 26, color: colors.WHITE, fontFamily: "Inter-Bold", textAlign: "center", display: "block", marginBottom: 20}}>
                CRIAR TIME
            </span>
			<Form onSubmit={createTeam}>
				<Input name="name">Nome do Time</Input>
				<Input name="icon">Ícone (Link)</Input>
				<Feedback id="feedbackCreateTeam" />
				<Button type="submit" disabled={loading} loading={loading}>CRIAR</Button>
			</Form>
        </Modal>

        <Modal id="teamInviteModal">
            <TeamModalContent>
                <img src={invite?.team.icon} alt={`Ícone do time ${invite?.team.name}`} className="picture" />
                <span className="title">{invite?.team.name}</span>
                <span className="members-count mb-2">Convite para entrar no time</span>
            
                <div className="invite-container">
                    <div className="mb-2">
                        <Button onClick={() => answerInvite(invite?.team.id, true)}>Aceitar</Button>
                    </div>
                    <OutlinedButton onClick={() => answerInvite(invite?.team.id, false)}>Recusar</OutlinedButton>
                </div>
            </TeamModalContent>
        </Modal>
    </Template>
    );
}

export default Teams;