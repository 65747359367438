import { faArrowDownLong, faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../constants/colors";
import { ChangeCoinDivButton } from "./styles";

function ChangeCoinButton(props: React.HTMLAttributes<HTMLButtonElement>) {
	return <ChangeCoinDivButton {...props}> 
		<FontAwesomeIcon icon={faArrowUpLong} size="2x" color={colors.PRIMARY_GREEN} />
		<FontAwesomeIcon icon={faArrowDownLong} size="2x" color={colors.PRIMARY_GREEN} />
	</ChangeCoinDivButton>;
}

export default ChangeCoinButton;