import styled from "styled-components";

export const ChangeCoinDivButton = styled.button`
    background-color: transparent;
    border: none;
    transition: 0.5s;
    margin-right: 5px;

    &:hover {
        cursor: pointer;
    }

    &:active {
        transform: scale(0.9);
    }
`;