import styled from "styled-components";
import colors from "../../components/UI/constants/colors";

export const TeamModalContent = styled.div`
    & .mb-2 {
        margin-bottom: 20px;
    }
    
    & .picture {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto;
        border-radius: 50%;
    }

    & .title {
        color: ${colors.WHITE};
        font-size: 27px;
        font-weight: bold;
        text-align: center;
        display: block;
    }

    & .member-title {
        font-size: 20px;
        margin-top: 30px;
    }

    & .members-count {
        color: ${colors.WHITE};
        text-align: center;
        display: block;
    }

    & .member-list {
        height: 210px;
        overflow-y: scroll;

        & .member {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            & .user-data {
                display: flex;
                align-items: center;
                
                & .profile_picture {
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                & .nickname {
                    display: inline-block;
                    margin-right: 10px;
                    color: white;
                }

                & .badge {
                    color: ${colors.PRIMARY_GREEN};
                    font-weight: bold;
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }
        }   
    }

    & .invite-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        & .input-container {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            width: 0;
            height: 0;
            transition: width .3s ease;
            overflow: hidden;
            &.open {
                width: 100%;
                height: 60px;
            }
        }
    }
` 

export const TitleContainer = styled.div`
    & button {
        margin-left: 51px;
        margin-bottom: 30px;
    }
`