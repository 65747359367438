import { faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../../../contexts/User";
import getFormatedTime from "../../../../../functions/getFormatedTime";
import axiosInstance from "../../../../../services/axiosInstance";
import { Chats } from "../../../../../types/Chats";
import { User } from "../../../../../types/User";
import colors from "../../../constants/colors";
import { CloseChat } from "../../../../../functions/chatFunctions";
import { ConversationType } from "../../Chat";
import { ChatBody, ChatContainerMessages, ChatContainerText, ChatHeader, ChatHeaderProfile, ProfilePicture } from "./styles";
import { Messages } from "../../../../../types/Messages";
import { Conversations } from "../../../../../types/Conversations";

function List(props: { setPage: React.Dispatch<React.SetStateAction<"list" | "conversation">>, setConversation: React.Dispatch<React.SetStateAction<ConversationType | null>>, setMessages: React.Dispatch<React.SetStateAction<Messages | null>>, setNextPage: React.Dispatch<React.SetStateAction<string | null>> }) {
	const userData = useContext(UserContext);
	const [chats, setChats] = useState<Chats | null>(null);

	useEffect(() => {
		axiosInstance.get("api/chat/chats").then((response) => {
			setChats(response.data);
		});
	}, []);

	function getChatTitle(members: User["user"][]) {
		let str = "";
		if (members.length > 2) {
			members.map((member, i, row) => {
				if(!member){
					return;
				}

				if (i === 0) {
					str += member.nickname;
				} else if (i === row.length - 1) {
					str += ` e ${member.nickname}`;
				} else {
					str += `, ${member.nickname}`;
				}
			});
		}
		else {
			const membersExceptMe = getMembersExceptMe(members);
			
			str += membersExceptMe[0].nickname;
		}
		return str;
	}

	function getMembersExceptMe(members: User["user"][]) {
		const membersExceptMe = members.filter((member) => {
			return member.id != userData?.user?.user.id;
		});

		if(membersExceptMe.length < 1)
			return members;

		return membersExceptMe;
	}

	function getMessages(chat: Conversations) {
		const groupPicture = <div style={{ height: 50, width: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: colors.THIRD_BLACK, borderRadius: "50%", flexShrink: 0 }}><FontAwesomeIcon style={{width: 30, height: 30}} color={colors.PRIMARY_GREEN} icon={faUsers} /></div>;
		const chatPicture = chat.members.length > 2 ? groupPicture : <ProfilePicture src={getMembersExceptMe(chat.members)[0].profile_picture} />;

		axiosInstance.get(`api/chat/${chat.id}/messages`).then((response) => {
			props.setMessages(response.data.messages);
			props.setNextPage(response.data.messages.next_page_url);
		}).finally(() => {
			props.setConversation({ conversation: chat, name: getChatTitle(chat.members), chatPicture }), props.setPage("conversation");
		});
	}
	
	return <React.Fragment>
		<ChatHeader>
			<div style={{ display: "flex", alignItems: "center" }}>
				<ChatHeaderProfile src={userData?.user?.user.profile_picture} />
				<span style={{ color: colors.WHITE, fontFamily: "Inter-Bold", fontSize: 22, marginLeft: 11 }}>Conversas</span>
			</div>
			<FontAwesomeIcon onClick={() => CloseChat()} color={colors.PRIMARY_GREEN} icon={faTimes} size="2x" style={{ cursor: "pointer" }}/>
		</ChatHeader>
		<ChatBody>
			{chats?.data && chats.data.length > 0 ? chats.data.map((chat) => (
				<ChatContainerMessages onClick={() => { getMessages(chat); }} key={chat.id}>
					{
						chat.members.length > 2 ?
							<div style={{ height: 50, width: 50, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: colors.THIRD_BLACK, borderRadius: "50%", flexShrink: 0 }}><FontAwesomeIcon color={colors.PRIMARY_GREEN} icon={faUsers} size="2x" /></div> :
							<ProfilePicture src={getMembersExceptMe(chat.members)[0].profile_picture} />
					}

					<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginLeft: 11, height: "100%", width: "100%", padding: "3px 0 3px 0" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<ChatContainerText style={{ fontWeight: "bold", fontSize: 16 }}>{getChatTitle(chat.members)}</ChatContainerText>
							<ChatContainerText style={{ color: colors.SECONDARY_GRAY, fontSize: 12, alignSelf: "end" }}>{chat.last_message?.updated_at ? getFormatedTime(chat.last_message?.updated_at) : ""}</ChatContainerText>
						</div>
						<ChatContainerText style={{ alignSelf: "start", fontSize: 16 }}>{chat.last_message?.message}</ChatContainerText>
					</div>
				</ChatContainerMessages>
			)) : <span style={{ fontSize: 22, color: colors.WHITE, alignSelf: "center", marginTop: 25 }}>Nenhuma conversa</span>}
		</ChatBody></React.Fragment>;
}

export default List;