import { useState } from "react";
import { OpenModal } from "../../../../functions/modalFunctions";
import { TeamType } from "../../../../types/Team";
import { MembersInfo, Picture, TeamContainer, TeamData, Title } from "./styles";
import axiosInstance from "../../../../services/axiosInstance";

const Team = (props: { team: TeamType, selectTeamFunction: () => void }) => {
    const { team, selectTeamFunction } = props;

    const onClickTeam = () => {
        OpenModal("selected_team_modal");
        selectTeamFunction();
    }

    return(
        <TeamContainer onClick={onClickTeam}>
            <Picture src={team?.icon} />
            <TeamData>
                <Title>{team?.name}</Title>
                <MembersInfo>{team?.members_count}/50 Membros</MembersInfo>
            </TeamData>
        </TeamContainer>
    );
}

export default Team;