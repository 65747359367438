import styled from "styled-components";
import colors from "../../components/UI/constants/colors";

export const PerfilContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
`;

export const UserName = styled.span`
    color: ${colors.WHITE};
    font-size: 26px;
`;

export const AmountContainer = styled.div`
    padding: 18px;
    background-color: ${colors.PRIMARY_GRAY};
    width: fit-content;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Amount = styled.span`
    margin-left: 5px;
    margin-right: 10px;
    font-size: 38px;
    color: ${colors.WHITE};
`;

export const ActionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const TransactionsContainer = styled.div`
    max-width: 600px;
    width: 100%;
    display: flex;
    background-color: ${colors.PRIMARY_GRAY};
    padding: 18px;
    border-radius: 10px;
    margin-right: auto;
    margin-left: auto;
    gap: 20px;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 600px;
`;

export const TransactionStyle = styled.div` 
    width: 100%;
    display: flex;
    max-width: 100%;
    background-color: ${colors.SECONDARY_GRAY};
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
`;

export const TransactionMessage = styled.span`
    color: ${props => props.color};
    padding: 5px;
    font-size: 24px;
`;

export const TransactionValue = styled.span`
    color: ${props => props.color};
    padding: 5px;
    font-size: 24px;
    display:flex;
    flex-wrap: no-wrap;
`;
