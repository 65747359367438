import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/User";
import { CloseMenu } from "../../functions/menuFunctions";
import OptionsPage from "./OptionsPage";
import RequestPage from "./PasswordRecovery/RequestPage";
import { MenuBlackSpace, MenuContainer, MenuContent } from "./styles";
import UserPage from "./UserPage";
import ConfirmPage from "./UserPage/ConfirmPage";
import LoginPage from "./UserPage/LoginPage";
import SigninPage from "./UserPage/SigninPage";

const Menu = () => {
	const User = useContext(UserContext);

	const [pages, setPages] = useState([
		{
			name: "UserPage",
			visible: true,
		},
		{
			name: "OptionsPage",
			visible: false,
		},
		{
			name: "LoginPage",
			visible: false,
		},
		{
			name: "SigninPage",
			visible: false,
		},
		{
			name: "ConfirmPage",
			visible: false,
		},
		{
			name: "RequestPage",
			visible: false
		}
	]);

	useEffect(() => {
		if (User?.user) {
			setPages([
				{
					name: "UserPage",
					visible: false,
				},
				{
					name: "OptionsPage",
					visible: true,
				},
				{
					name: "LoginPage",
					visible: false,
				},
				{
					name: "SigninPage",
					visible: false,
				},
				{
					name: "ConfirmPage",
					visible: false,
				},
				{
					name: "RequestPage",
					visible: false
				}
			]);
		}
		else {
			setPages([
				{
					name: "UserPage",
					visible: true,
				},
				{
					name: "OptionsPage",
					visible: false,
				},
				{
					name: "LoginPage",
					visible: false,
				},
				{
					name: "SigninPage",
					visible: false,
				},
				{
					name: "ConfirmPage",
					visible: false,
				},
				{
					name: "RequestPage",
					visible: false
				}
			]);
		}
	}, [User]);

	const changePage = (page: string) => {
		setPages(pages.map(p => {
			p.visible = p.name === page;
			return p;
		}));
	};

	return (<MenuContainer id="menu">
		<MenuBlackSpace onClick={() => {
			CloseMenu();
		}} />
		<MenuContent>
			<UserPage visible={pages[0].visible} changePage={changePage} />
			<OptionsPage visible={pages[1].visible} changePage={changePage} />
			<LoginPage visible={pages[2].visible} changePage={changePage} />
			<SigninPage visible={pages[3].visible} changePage={changePage} />
			<ConfirmPage visible={pages[4].visible} changePage={changePage} />
			<RequestPage visible={pages[5].visible} changePage={changePage} />
		</MenuContent>
	</MenuContainer>);
};

export default Menu;