import { createContext, useState } from "react";

type WalletCoinTypeContextProps = {
	type: "ggcoin" | "money" | undefined;
	invertType: (type: "ggcoin" | "money") => void;
}

const WalletCoinTypeContext = createContext<WalletCoinTypeContextProps>({
	type: (localStorage.getItem("gghub@walletCoinType") === "money" ? "money" : "ggcoin"), invertType: () => {
		return;
	}
});

const WalletCoinTypeContextProvider = ({ children }: { children: JSX.Element }) => {
	const [type, setType] = useState<"ggcoin" | "money">(localStorage.getItem("gghub@walletCoinType") === "ggcoin" ? "ggcoin" : "money");

	function invertType() {
		setType(type === "ggcoin" ? "money" : "ggcoin");
		localStorage.setItem("gghub@walletCoinType", `${type === "ggcoin" ? "money" : "ggcoin"}`);
	}

	return <WalletCoinTypeContext.Provider value={{ type, invertType }}>{children}</WalletCoinTypeContext.Provider>;
};

export { WalletCoinTypeContextProvider };
export default WalletCoinTypeContext;