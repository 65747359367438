import { faTimes, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../contexts/User";
import { CloseMenu } from "../../../functions/menuFunctions";
import IconButton from "../../UI/Buttons/IconButton";
import OutlinedButton from "../../UI/Buttons/OutlinedButton";
import colors from "../../UI/constants/colors";
import UserPic from "../../UI/UserPic/UserPic";
import { MenuActions, MenuHeader } from "../styles";

const OptionsPage = (props: { visible?: boolean, changePage: (page: string) => void; }) => {
	const navigate = useNavigate();
	const Data = useContext(UserContext);

	if (!props.visible) {
		return <></>;
	}

	function NavigateTo(path: string) {
		window.hasPreviousUrl = true;
		navigate(path);
		CloseMenu();
	}

	function handleLogout() {
		localStorage.removeItem("gghub@user");
		Data?.setUser(undefined);
	}

	return (
		<>
			<MenuHeader>
				<IconButton className="close-menu" onClick={() => {
					CloseMenu();
				}} icon={faTimes} color={colors.PRIMARY_GREEN} nobackground />
			</MenuHeader>
			<MenuActions>
				<UserPic onClick={() => { NavigateTo("/minha-conta"); }}>
					{Data?.user?.user && Data?.user?.user.profile_picture ? <img
						src={Data?.user?.user.profile_picture} width="100%" height="100%" /> : <FontAwesomeIcon icon={faUserAstronaut} color={colors.SECONDARY_GRAY} size="4x" />}
				</UserPic>
				<span style={{ color: "#FFF", fontSize: 28, maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis", minHeight: 34 }}>{Data?.user?.user && Data?.user?.user.nickname}</span>
				<OutlinedButton onClick={() => NavigateTo("/minha-carteira")}>
					CARTEIRA
				</OutlinedButton>
				<OutlinedButton onClick={() => NavigateTo("/meus-desafios")}>
					MEUS DESAFIOS
				</OutlinedButton>
				<OutlinedButton onClick={() => NavigateTo("/meus-times")}>
					MEUS TIMES
				</OutlinedButton>
				<OutlinedButton onClick={() => {
					NavigateTo("/minha-conta");
				}}>
					CONFIGURAÇÕES
				</OutlinedButton>
				<OutlinedButton danger onClick={() => handleLogout()}>
					SAIR
				</OutlinedButton>
			</MenuActions>
		</>
	);
};

export default OptionsPage;