import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import { GlobalStyle } from "./AppStyles";
import { UserContextProvider } from "./contexts/User";
import Account from "./Pages/Account";
import Wallet from "./Pages/Wallet/Wallet";
import { WalletVisionContextProvider } from "./contexts/WalletVision";
import Game from "./Pages/Home/Game";
import Tournament from "./Pages/Tournament/Tournament";
import Challenge from "./Pages/Challenge/Challenge";
import MyChallenges from "./Pages/MyChallenges";
import UserTermsAndPrivacy from "./Pages/Legal/UserTermsAndPrivacy";
import Tournaments from "./Pages/Tournaments/Tournaments";
import { WalletCoinTypeContextProvider } from "./contexts/WalletCoinType";
import Teams from "./Pages/Teams";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<StrictMode>
		<GlobalStyle />
		<UserContextProvider>
			<WalletVisionContextProvider>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/minha-conta" element={<Account />} />
						<Route path="/minha-carteira" element={<Wallet />} />
						<Route path="/jogo/:game" element={<Game />} />
						<Route path="/torneio/:id" element={<Tournament />} />
						<Route path="/torneios" element={<Tournaments />} />
						<Route path="/desafio/:id" element={<Challenge />} />
						<Route path="/meus-desafios" element={<MyChallenges />} />
						<Route path="/meus-times" element={<Teams />} />
						<Route path="/termos-de-uso-e-privacidade" element={<UserTermsAndPrivacy /> } />
					</Routes>
				</BrowserRouter>
			</WalletVisionContextProvider>
		</UserContextProvider>
	</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
