import { faBars, faChevronLeft, faCoins, faMessage } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import logo from "../../assets/Icons/Logo/SVG/LOGO_GGHUB_DUO_WHITE_GREEN.svg";
import IconButton from "../UI/Buttons/IconButton";
import colors from "../UI/constants/colors";
import { BalanceButton, Container, NotificationButton, NotificationsDiv, NotificationText, SecondaryContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import { OpenMenu } from "../../functions/menuFunctions";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import UserContext from "../../contexts/User";
import WalletVisionContext from "../../contexts/WalletVision";
import { OpenChat } from "../../functions/chatFunctions";
import { Notifications } from "../../types/Notifications";
import axiosInstance from "../../services/axiosInstance";
import ChangeCoinButton from "../UI/Buttons/ChangeCoinButton/ChangeCoinButton";
import WalletCoinTypeContext from "../../contexts/WalletCoinType";

const Header = (props: { secondary?: boolean, icon?: FontAwesomeIconProps["icon"] | null, text?: string | null }) => {
	const navigate = useNavigate();
	const HideBallance = useContext(WalletVisionContext);
	const walletCoinType = useContext(WalletCoinTypeContext);
	const UserData = useContext(UserContext);
	const [notifications, setNotifications] = useState<Notifications[] | null>(null);

	function NavigateTo(route: string) {
		window.hasPreviousUrl = true;
		navigate(route);
	}

	useEffect(() => {
		axiosInstance.get("/api/notifications/all").then((response) => {
			setNotifications(response.data);
		});
	}, []);

	function returnCoins() {
		if (!UserData?.user || !HideBallance.visible) {
			return;
		}

		if (walletCoinType.type === "money") {
			return <span><span style={{ color: colors.THIRD_GREEN }}>R$ </span>{UserData?.user?.wallet.balance}</span>;
		}

		return <span><FontAwesomeIcon icon={faCoins} style={{ position: "relative", top: 0, color: colors.THIRD_GREEN, marginRight: 5 }} size="1x" />{UserData?.user?.wallet.coins}</span>;
	}

	if (props.secondary) {
		return (
			<SecondaryContainer>
				<IconButton icon={faChevronLeft} onClick={() => {
					if (window.hasPreviousUrl) {
						return navigate(-1);
					} return navigate("/");
				}} nobackground color={colors.PRIMARY_GREEN} />
				{props.icon && props.text ?
					<IconButton icon={props.icon} nobackground color={colors.PRIMARY_GREEN} >
						{props.text}
					</IconButton>
					:
					null
				}

			</SecondaryContainer>
		);
	}

	return (
		<Container>
			<div style={{ position: "relative" }}>
				<img src={logo} style={{ cursor: "pointer", padding: 0, height: 24, width: 112.8 }} onClick={() => NavigateTo("/")} alt="GGHub logo" />
				<span style={{ position: "absolute", fontFamily: "Galano-Bold", fontSize: 20, color: colors.WHITE, top: 10, right: -50 }}>Beta</span>
			</div>
			{<div style={{display: HideBallance.visible ? "block" : "none"}}><BalanceButton onClick={() => { NavigateTo("/minha-carteira"); }} >{returnCoins()}</BalanceButton><ChangeCoinButton onClick={() => {
				if (walletCoinType.type === "money") {
					return walletCoinType.invertType("ggcoin");
				}
				return walletCoinType.invertType("money");
			}} style={{ marginLeft: 5 }} /></div>}

			<div style={{ display: "flex", flexDirection: "row", gap: 10, position: "relative" }}>
				{UserData?.user ? <IconButton ariaLabel="Message" styles={{ position: "relative", top: 2 }} id="messageButton" icon={faMessage} color={colors.PRIMARY_GREEN} nobackground onClick={() => { return OpenChat(); }}></IconButton> : null}
				<IconButton ariaLabel="Menu" id="menuButton" icon={faBars} color={colors.PRIMARY_GREEN} nobackground onClick={() => {
					OpenMenu();
				}}></IconButton>
			</div>
		</Container>
	);
};

export default Header;