import styled from "styled-components";
import colors from "../../../../components/UI/constants/colors";

export const TeamContainer = styled.div`
    height: 74px;
    width: 375px;
    max-width: 100%;
    border: ${colors.PRIMARY_GREEN} solid 1px;
    border-radius: 5px;
    background-color: ${colors.PRIMARY_GRAY};
    display: flex;
    align-items: center;
    padding-left: 11px;
    margin-right: 32px;
    margin-bottom: 32px;
`

export const Picture = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
`

export const TeamData = styled.div`
    color: ${colors.WHITE};
    display: inline-block;
    margin-left: 5px;
`

export const Title = styled.span`
    font-size: 16px;
    font-weight: bold;
    display: block;
`

export const MembersInfo = styled.span`
    font-size: 12px;
    display: block;
`