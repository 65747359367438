import { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Template from "../../components/Template";
import axiosInstance from "../../services/axiosInstance";
import { Challenges } from "../../types/Challenges";
import LeagueOfLegendsBackgroundChallenge from "../../assets/Images/LeagueOfLegendsBackgroundChallenge.webp";
import { Banner, Details, Divisor, Users, Topic, Value, Title, RulesContainer, VoteContainer } from "./styles";
import ChallengePlayer from "./ChallengePlayer";
import { faHandFist } from "@fortawesome/free-solid-svg-icons";
import colors from "../../components/UI/constants/colors";
import Button from "../../components/UI/Buttons/Button";
import UserContext from "../../contexts/User";
import OutlinedButton from "../../components/UI/Buttons/OutlinedButton";
import seo from "../../helpers/seo";
import { OpenModal } from "../../functions/modalFunctions";
import VoteWarning from "./Modals/VoteWarning";
import EntryWarning from "./Modals/EntryWarning";
import ReportWarning from "./Modals/ReportWarning";
import { setFeedbackMassage } from "../../functions/feedbackFunctions";
import Conversation from "./Conversation/Conversation";
import { Conversations } from "../../types/Conversations";
import { User } from "../../types/User";
import { Messages } from "../../types/Messages";

const jogos = {
	"lol": "League of Legends",
	"csgo": "Counter Strike: Global Offensive",
	"ow": "Overwatch",
	"dota2": "Dota 2",
	"pubg": "PUBG",
	"smite": "Smite",
	"fifa": "FIFA",
	"r6": "Rainbow Six Siege",
	"wildrift": "Wild Rift",
	"generic": "Genérico"
};

const mapas = {
	"summonersrift": "Summoner's Rift",
	"towersofdoom": "Towers of Doom",
	"howlingabyss": "Howling Abyss",
	"crystalScar": "Crystal Scar",
	"twistedTreeline": "Twisted Treeline",
	"butchersBridge": "Butcher's Bridge",
};

const pickMode = {
	"blind": "Às cegas",
	"draft": "Escolha alternada",
	"random": "Aleatório",
};

const status = {
	"searching": "Procurando...",
	"ingame": "Lutando...",
	"validating": "Validando...",
	"ended": "Finalizado",
	"reported": "Jogo reportado",
};

const winConditions = {
	"firstblood": "First Blood",
	"destroynexus": "Destruir Nexus",
	"firsttower": "Primeira torre",
	"goldlimit": "Primeiro 100 de ouro",
	"canoutlane": "Não pode sair de lane",
};

const rules = {
	"canoutlane": "Não pode sair de lane",
	"cantrecall": "Não pode ir base",
};

const Challenge = () => {
	const { id } = useParams();
	const [challenge, setChallenge] = useState<Challenges>();
	const Data = useContext(UserContext);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [win, setWin] = useState(false);
	const [lose, setLose] = useState(false);
	const [report, setReport] = useState(false);
	const [voted, setVoted] = useState(false);
	const [condition, setCondition] = useState("");
	const [type, setType] = useState<number>();
	const [chat, setChat] = useState<Conversations>();
	const [nextPage, setNextPage] = useState<string>("");
	const [messages, setMessages] = useState<Messages | null>(null);

	useEffect(() => {
		if (Data?.user?.user.id && challenge?.playerA.id && challenge?.playerB?.id) {
			setChat(challenge?.chat);
			
			axiosInstance.get(`api/chat/${challenge?.chat.id}/messages`).then((response)=> {
				if(response.data.messages){
					setMessages(response.data.messages);
					setNextPage(response.data.messages.next_page_url);
				}
			});
		}
	}, [Data?.user?.user.id, challenge?.playerA.id, challenge?.playerB?.id]);

	useEffect(() => {
		requestChallengeData();
		const intervalId = setInterval(requestChallengeData, 15000);
		return () => clearInterval(intervalId);
	}, []);

	function requestChallengeData() {
		axiosInstance.get(`/api/matches/solo/${id}`).then(res => {
			setChallenge(res.data);
			seo({
				title: res.data.title
			});
		});
	}

	useEffect(() => {
		requestChallengeData();
		const intervalId = setInterval(requestChallengeData, 15000);
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (challenge?.status === "ingame" || challenge?.status === "validating") {
			if (challenge.playerA.id === Data?.user?.user.id && challenge?.result_playerA !== null) {
				return setVoted(true);
			}
			if (challenge?.playerB?.id === Data?.user?.user.id && challenge?.result_playerB !== null) {
				return setVoted(true);
			}
		}
	}, [challenge]);

	function handleCancel() {
		setLoading(true);
		axiosInstance.post(`/api/matches/solo/${challenge?.id}/cancel`).then(res => {
			if (res.status === 200) {
				navigate("/");
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	function handleJoin() {
		setLoading(true);
		axiosInstance.post(`/api/matches/solo/${challenge?.id}/join`, {
			"match": challenge?.id
		}).then(res => {
			if (res.status === 200) {
				window.location.reload();
			}
		}).catch((err) => {
			if (err.response.data.error) {
				setFeedbackMassage({
					id: "entryWarningFeedback",
					message: err.response.data.error,
					success: false
				});
			}
			else {
				setFeedbackMassage({
					id: "entryWarningFeedback",
					message: err.response.status,
					success: false
				});
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	function handleUsers() {
		return <Users>
			<ChallengePlayer challengeId={challenge?.id} player={challenge?.playerA} winner={challenge?.result_playerA} hasToBeReady={challenge?.playerA.id === Data?.user?.user.id} status={challenge?.status} />
			<Divisor>VS</Divisor>
			<ChallengePlayer hasToBeReady={challenge?.playerB?.id === Data?.user?.user.id} challengeId={challenge?.id} status={challenge?.status} player={challenge?.playerB} winner={challenge?.result_playerB} />
		</Users>;
	}

	function handleVote() {
		setLoading(true);
		// eslint-disable-next-line react/prop-types
		switch (condition) {
		case "win":
			setWin(true);
			break;
		case "lose":
			setLose(true);
			break;
		case "report":
			setReport(true);
			break;
		default:
			break;
		}
		// eslint-disable-next-line react/prop-types
		axiosInstance.post(`/api/matches/solo/${challenge?.id}/vote`, { "vote": type }).then(res => {
			if (res.status === 200) {
				setVoted(true);
				window.location.reload();
			}
		}).finally(() => {
			setLoading(false);
			// eslint-disable-next-line react/prop-types
			switch (condition) {
			case "win":
				setWin(false);
				break;
			case "lose":
				setLose(false);
				break;
			case "report":
				setReport(false);
				break;
			default:
				break;
			}
		});
	}


	function warnUser(condition: string, type: number) {
		OpenModal("voteWarning");
		setCondition(condition);
		setType(type);
	}

	function returnVoteText() {
		switch (condition) {
		case "win":
			return <span>Você está votando em <span style={{ color: colors.PRIMARY_GREEN }}>GANHEI</span></span>;
		case "lose":
			return <span>Você está votando em <span style={{ color: colors.PRIMARY_RED }}>PERDI</span></span>;
		case "report":
			return <span>Você está <span style={{ color: colors.WHITE }}>REPORTANDO</span> o resultado</span>;
		}
	}

	return <Template secondaryHeader icon={faHandFist} text="Desafio">
		<VoteWarning loading={loading} handleVote={() => handleVote()} returnVoteText={() => returnVoteText()} />
		<EntryWarning loading={loading} handleJoin={() => handleJoin()} />
		<ReportWarning challengeId={challenge?.id} setVoted={(e) => setVoted(e)} />
		<Banner background={LeagueOfLegendsBackgroundChallenge}><span>DESAFIO</span></Banner>
		<Details>
			{!challenge?.playerB && handleUsers()}
			{challenge?.status === "ingame" && handleUsers()}
			{challenge && <>
				<Topic>Jogo:
					<Value>
						{jogos[challenge.game as keyof typeof jogos]}
					</Value>
				</Topic>
				{["lol", "wildrift"].includes(challenge.game) ? <>
					<Topic>Mapa:
						<Value>
							{mapas[challenge.map as keyof typeof mapas]}
						</Value>
					</Topic>
					<Topic>Modo de seleção:
						<Value>
							{pickMode[challenge.pickmode as keyof typeof pickMode]}
						</Value>
					</Topic>
				</>
					: <></>
				}
				<Topic>Prêmio:
					<Value><span style={{ color: colors.THIRD_GREEN, fontWeight: "bold" }}>R$ </span>{challenge.prize}</Value>
				</Topic>
				<Topic>Custo:
					<Value><span style={{ color: colors.THIRD_GREEN, fontWeight: "bold" }}>R$ </span>{challenge.ticket}</Value>
				</Topic>
				<Topic>Status:
					<Value>
						{status[challenge.status as keyof typeof status]}
					</Value>
				</Topic>
			</>
			}
		</Details>
		<>
			{challenge?.playerB && challenge?.status !== "ingame" && handleUsers()}
		</>
		<>
			{challenge?.status === "ingame" && !voted && (challenge.playerA.id === Data?.user?.user.id ||
				challenge?.playerB?.id === Data?.user?.user.id) ? <VoteContainer>
					<Button onClick={() => warnUser("win", 1)} disabled={loading} loading={win}>VITÓRIA</Button>
					<Button onClick={() => warnUser("lose", 0)} disabled={loading} loading={lose}>DERROTA</Button>
					<OutlinedButton onClick={() => OpenModal("reportWarning")} danger disabled={loading} loading={report}>REPORTAR</OutlinedButton>
				</VoteContainer> : <></>}
		</>
		<Title>Detalhes</Title>
		{
			challenge?.rules || challenge?.win_conditions || challenge?.description ?
				<RulesContainer>
					{challenge?.rules ? <ul>{challenge.rules.split(";").map((condition, index) => {
						if (condition !== "") {
							return <li key={index} style={{ listStylePosition: "inside" }}>
								<span style={{ marginLeft: -15 }}>{rules[condition as keyof typeof rules] + ";"}</span>
							</li>;
						}

					}
					)}</ul> : <></>}
					{challenge?.win_conditions ?
						<span style={{ color: colors.PRIMARY_GREEN, fontSize: 22 }}>Vitória:
						</span> : <></>}
					{challenge?.win_conditions ? <ul>{challenge.win_conditions.split(";").map((condition, index) => {
						if (condition !== "") {
							return <li key={index} style={{ listStylePosition: "inside" }}>
								<span style={{ marginLeft: -15 }}>{winConditions[condition as keyof typeof winConditions] + ";"}</span>
							</li>;
						}

					}
					)}</ul> : <></>}
					{challenge?.description ?
						<span style={{ color: colors.WHITE, fontSize: 22 }}>{challenge?.description}</span>
						: <></>
					}
				</RulesContainer> : <></>}
		{chat ? <div style={{maxWidth: 500, margin: "auto", marginTop: 20}}><Conversation members={chat?.members} conversationId={chat?.id} messages={messages} nextPage={nextPage} /></div> : <></>}
		
		<>
			{challenge?.status === "searching" && Data?.user?.user.id !== challenge.playerA.id ? <div style={{ display: "flex", maxWidth: "100%", marginTop: 18, width: "fit-content", marginLeft: "auto", marginRight: "auto", alignItems: "center", gap: 18, flexDirection: "column" }}>
				<div><span style={{ color: colors.THIRD_GREEN, fontWeight: "bold", fontSize: 32 }}>R$ </span>
					<span style={{ color: colors.WHITE, fontSize: 32 }}>{challenge?.ticket}</span>
				</div>
				{Data?.user?.user ? <Button loading={loading} disabled={loading} onClick={() => OpenModal("entryWarning")}>
					DESAFIAR
				</Button> : <span style={{ color: colors.PRIMARY_GREEN, fontSize: 22 }}>Você deve estar logado para desafiar.</span>}
			</div> : <></>}
			<>
				{
					challenge?.status === "searching" && Data?.user?.user.id === challenge.playerA.id ?
						<div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 18 }}>
							<OutlinedButton loading={loading} disabled={loading} danger onClick={() => handleCancel()}>
								CANCELAR
							</OutlinedButton>
						</div> : <></>
				}
			</>
		</>
	</Template>;
};

export default Challenge;