import styled from "styled-components";
import colors from "../constants/colors";

export const ChatDiv = styled.div`
    display: none; 
    
    &.show {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      z-index: 99; 
      left: 0;
      top: 0;
      width: 100%; 
      height: 100%;
      background-color: rgb(0,0,0); 
      background-color: rgba(0,0,0,0.4);
    }
`;

export const ChatBlackSpace = styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
`;

export const ChatContainer = styled.div`
    height: 100%;
    width: 620px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.PRIMARY_BLACK};

    @media (max-width: 768px) {
      width: 100%;
    }

    padding-top: 15px;
`;

export const ChatHeader = styled.div`
  height: 100px;
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: calc(50% - 47%);
    width: 95%;
    border-bottom: 1px solid ${colors.PRIMARY_GREEN};
  }
  
`;

export const ChatHeaderProfile = styled.img`
	height: 80px;
	width: 80px;
	border-radius: 50%;
`;

export const ChatBody = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const ChatContainerMessages = styled.button`
	background-color: transparent;
	border: none;
  cursor: pointer;
	height: 90px;
	width: 100%;
	padding: 10px;
	padding-left: 20px;
	display: flex;
	align-items: center;
  transition: 0.5s;

  &:active {
    transform: scale(0.95);
  }
`;

export const ProfilePicture = styled.img`
	height: 60px;
	width: 60px;
	border-radius: 50%;
`;

export const ChatContainerText = styled.span`
	max-width: 300px;
	max-height: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: ${colors.WHITE};
`;